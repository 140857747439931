import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';

const CareersHero = ({ careersImages }) => (
  <Box className='body-layout'>
    <Flex flexWrap='wrap' py={['30px', '75px']}>
      <Box width={[1, 1, 1 / 2]} px={['0px', '20px']} mt={['0px', '30px', '40px', '135px']} mb={['30px', '50px']}>
        <Text color='nextonDarkGrey' fontSize={['24px', '30px']} lineHeight='1.2' letterSpacing='-1.5px' mb='10px'>
          Nexton Careers
        </Text>
        <Text
          color='nextonBlue'
          fontSize={['42px', '65px']}
          fontWeight='300'
          lineHeight='1.3'
          letterSpacing='-1.5px'
          as='h1'>
          We believe in freedom to be ourselves and{' '}
          <Text as='span' fontWeight='500'>
            bring out our best
          </Text>
        </Text>
      </Box>
      <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
        <GatsbyImage image={careersImages.ourBest} alt='Our Best' />
      </Box>
    </Flex>
  </Box>
);

export default CareersHero;
