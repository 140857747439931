import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

const LightBlueBubble = styled(Box)`
  background-color: #f5fbfd;
  position: absolute;
  bottom: 80px;
  right: 0%;
  width: 381px;
  height: 381px;
  border-radius: 50%;
  z-index: -1;
`;

const JobOpeningsBubbles = () => (
  <Box>
    <LightBlueBubble />
  </Box>
);

export default JobOpeningsBubbles;
