import React from 'react';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const camelCase = require('camelcase');

const StyledBenefit = styled(Box)`
  box-shadow: 0px 20px 20px -14px rgba(161, 161, 161, 0.160047);
  border-radius: 12px;
  background-color: white;
  padding: 24px;
  margin: 12px;

  color: ${({ theme }) => theme.colors.nextonDarkGrey};
  line-height: 1.3;
  letter-spacing: -1.5px;
`;

const BenefitItem = ({ image, text }) => (
  <Box width={[1, 1, 1 / 2]}>
    <StyledBenefit>
      <Flex alignItems='center'>
        <Box mr='20px'>
          <GatsbyImage image={image} alt={text} />
        </Box>
        <Text fontSize={['24px', '28px']}>{text}</Text>
      </Flex>
    </StyledBenefit>
  </Box>
);

const Benefits = () => {
  const { benefits } = useStaticQuery(graphql`
    {
      benefits: allFile(filter: { relativeDirectory: { eq: "images/careers/benefits" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FIXED, formats: [PNG])
          }
          name
        }
      }
    }
  `);
  const benefitsImages = {};
  benefits.nodes.forEach(img => {
    benefitsImages[camelCase(img.name)] = { ...img.childImageSharp.gatsbyImageData };
  });

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      py='45px'
      my='30px'
      px={['0px', '20px']}
      bg='nextonLightGrey'
      style={{ borderRadius: '24px' }}>
      <Text
        color='nextonDarkGrey'
        fontSize={['40px', '50px']}
        letterSpacing='-1.5px'
        mb='30px'
        as='h2'
        fontWeight='400'>
        Benefits
      </Text>
      <Flex flexWrap='wrap'>
        <BenefitItem image={benefitsImages.pto} text='Paid Time Off' />
        <BenefitItem image={benefitsImages.beers} text='Company Offsites & Nexton Beers' />
        <BenefitItem image={benefitsImages.home} text='Four-day Workweek' />
        <BenefitItem image={benefitsImages.schedules} text='Flexible Work Schedules' />
        <BenefitItem image={benefitsImages.payments} text='Crypto Payments' />
        <BenefitItem image={benefitsImages.maternity} text='Maternity / Paternity Leave' />
        <BenefitItem image={benefitsImages.birthday} text='Birthday Gifts' />
        <BenefitItem image={benefitsImages.educational} text='Educational Budget' />
        <BenefitItem image={benefitsImages.yoga} text='Wellness Program' />
        <BenefitItem image={benefitsImages.classes} text='Languages Classes' />
      </Flex>
    </Flex>
  );
};

export default Benefits;
