import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useMediaQuery } from 'react-responsive';
import LoadableTimelineXarrow from '../../components/academy/LoadableTimelineXarrow';

const camelCase = require('camelcase');

const SelectionItem = ({ icon, id, title }) => (
  <Flex flexDirection='column' alignItems='center' justifyContent='flexStart' my='35px' width='160px'>
    <Box id={id}>
      <GatsbyImage image={icon} alt={title} />
    </Box>
    <Text color='nextonBlue' fontSize={['22px', '24px']} letterSpacing='-1.5px' textAlign='center' mt='12px'>
      {title}
    </Text>
  </Flex>
);

const SelectionProcess = () => {
  const { selection } = useStaticQuery(graphql`
    {
      selection: allFile(filter: { relativeDirectory: { eq: "images/careers/selection" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED, formats: [PNG])
          }
          name
        }
      }
    }
  `);
  const selectionImages = {};
  selection.nodes.forEach(img => {
    selectionImages[camelCase(img.name)] = { ...img.childImageSharp.gatsbyImageData };
  });

  const isMiniMobile = useMediaQuery({ maxWidth: 359 });
  const isMin520px = useMediaQuery({ minWidth: 520 });
  const is520to719 = useMediaQuery({ minWidth: 520, maxWidth: 719 });
  const isMin880px = useMediaQuery({ minWidth: 880 });
  const is880to1039 = useMediaQuery({ minWidth: 880, maxWidth: 1039 });

  return (
    <Flex flexDirection='column' alignItems='center' pt='75px' pb='40px' px={['0px', '20px']} width='100%'>
      <Text color='nextonBlue' fontSize={['40px', '50px']} letterSpacing='-1.5px' mb='30px' as='h2' fontWeight='400'>
        Selection Process
      </Text>
      <Box width='100%'>
        <Flex flexWrap='wrap' flexGrow='grow' justifyContent={['center', 'center', 'space-around']} width='100%'>
          <SelectionItem icon={selectionImages.application} id='applicationIcon' title='Fill out the job application' />
          {!isMiniMobile && <LoadableTimelineXarrow start='applicationIcon' end='screeningIcon' />}

          <SelectionItem icon={selectionImages.screening} id='screeningIcon' title='Have a first screening' />
          {!isMiniMobile && isMin520px && <LoadableTimelineXarrow start='screeningIcon' end='interviewIcon' />}

          <SelectionItem icon={selectionImages.interview} id='interviewIcon' title='Interview with hiring managers' />
          {!isMiniMobile && !is520to719 && <LoadableTimelineXarrow start='interviewIcon' end='challengeIcon' />}

          <SelectionItem icon={selectionImages.challenge} id='challengeIcon' title='Challenge' />
          {(is520to719 || isMin880px) && <LoadableTimelineXarrow start='challengeIcon' end='reviewIcon' />}

          <SelectionItem icon={selectionImages.review} id='reviewIcon' title='Challenge Review' />
          {!isMiniMobile && !is880to1039 && <LoadableTimelineXarrow start='reviewIcon' end='managerIcon' />}

          <SelectionItem icon={selectionImages.manager} id='managerIcon' title='Interview with area manager' />
        </Flex>
      </Box>
    </Flex>
  );
};

export default SelectionProcess;
