import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from 'rebass';
import JobOpeningsBubbles from '../../components/careers/JobOpeningsBubbles';
import '../../../assets/job-openings.scss';

const JobOpenings = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const clipNEPrefix = list => {
    Array.from(list).forEach(item => {
      // eslint-disable-next-line no-param-reassign
      item.firstChild.innerHTML = item.firstChild.innerHTML.replace('NE-', '');
    });
  };

  useEffect(() => {
    const careerScript = document.createElement('script');
    careerScript.src =
      'https://static.zohocdn.com/recruit/embed_careers_site/javascript/v1.0/embed_jobs.5a71e72320d517a462350c6022ccfe36.js';
    careerScript.addEventListener('load', () => setScriptLoaded(true));
    document.body.appendChild(careerScript);
  }, []);

  useEffect(() => {
    if (!scriptLoaded) return;

    // eslint-disable-next-line camelcase, no-undef
    rec_embed_js.load({
      widget_id: 'rec_job_listing_div',
      page_name: 'Careers',
      source: 'CareerSite',
      site: 'https://nextonlabs.zohorecruit.com',
      empty_job_msg: 'No current Openings',
    });

    const JobIntervalChecker = setInterval(() => {
      const JobContainer = document.getElementById('rec_job_listing_div');
      const JobList = JobContainer.getElementsByClassName('rec-job-title');

      if (JobList.length > 0) {
        clearInterval(JobIntervalChecker);
        clipNEPrefix(JobList);
      }
    }, 150);
  }, [scriptLoaded]);

  return (
    <Box py={['75px', '75px', '100px']} pb='140px' style={{ position: 'relative' }}>
      <Flex flexWrap='wrap' alignItems='flex-start' justifyContent='center'>
        <Box px={['0px', '20px']} textAlign='center'>
          <Text
            color='nextonBlue'
            fontSize={['40px', '50px']}
            letterSpacing='-1.5px'
            pb='25px'
            as='h2'
            fontWeight='400'>
            Job Openings
          </Text>
          <Text
            color='nextonDarkGrey'
            fontSize={['22px', '28px']}
            lineHeight='1.3'
            letterSpacing='-1.5px'
            textAlign='center'
            // maxWidth="905px"
            mb='55px'>
            Do you want to work at Nexton? Check out our job openings
          </Text>
        </Box>
        <Box px={['0px', '20px']} width='100%'>
          <div className='embed_jobs_head embed_jobs_with_style_1 embed_jobs_with_style'>
            <div className='embed_jobs_head2'>
              <div className='embed_jobs_head3'>
                <div id='rec_job_listing_div' />
              </div>
            </div>
          </div>
        </Box>
      </Flex>
      <JobOpeningsBubbles />
    </Box>
  );
};

export default JobOpenings;
