import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';

const RemoteWork = ({ careersImages }) => (
  <Flex flexDirection='column' alignItems='center' justifyContent='center' py='75px' px={['0px', '20px']}>
    <Text color='nextonBlue' fontSize={['40px', '50px']} letterSpacing='-1.5px' pb='25px' as='h2' fontWeight='400'>
      Remote Work
    </Text>
    <Text
      color='nextonDarkGrey'
      fontSize={['22px', '28px']}
      lineHeight='1.3'
      letterSpacing='-1.5px'
      textAlign='center'
      maxWidth='905px'
      mb='55px'>
      We are 100% remote. The freedom to work where you chose is in our DNA. Remote work is based on trust and effective
      communication to carry out our tasks in the most effective way.
    </Text>
    <Box mx={['-20px', '0px']}>
      <GatsbyImage image={careersImages.remoteWorkMap} alt='Remote Work Map' />
    </Box>
  </Flex>
);

export default RemoteWork;
