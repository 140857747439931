import React from 'react';
import Loadable from 'react-loadable';

const LoadableTimelineXarrow = Loadable({
  loader: () => import('./TimelineXarrow'),
  loading() {
    return <></>;
  },
});

export default LoadableTimelineXarrow;
