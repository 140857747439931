import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';
import CareersHero from '../sections/careers/CareersHero';
import OurValues from '../sections/careers/OurValues';
import Benefits from '../sections/careers/Benefits';
import RemoteWork from '../sections/careers/RemoteWork';
import SelectionProcess from '../sections/careers/SelectionProcess';
import JobOpenings from '../sections/careers/JobOpenings';

const camelCase = require('camelcase');

const CareersPage = () => {
  const { careers } = useStaticQuery(graphql`
    {
      careers: allFile(filter: { relativeDirectory: { eq: "images/careers" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED, formats: [PNG])
          }
          name
        }
      }
    }
  `);
  const careersImages = {};
  careers.nodes.forEach(img => {
    careersImages[camelCase(img.name)] = { ...img.childImageSharp.gatsbyImageData };
  });

  return (
    <>
      <MetaData
        title='Careers'
        description='We believe in freedom to be ourselves and bring out our best'
        image='https://www.nextonlabs.com/careers-banner.png'
      />
      <Layout selected='careers'>
        <CareersHero careersImages={careersImages} />
        <OurValues />
        <Benefits />
        <RemoteWork careersImages={careersImages} />
        <SelectionProcess />
        <JobOpenings />
      </Layout>
    </>
  );
};

export default CareersPage;
