import React from 'react';
import { Flex, Text } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const camelCase = require('camelcase');

const ValueItem = ({ image, title, subtitle }) => (
  <Flex
    flexDirection='column'
    alignItems='center'
    justifyContent='flexStart'
    width={[1, 1 / 2, 1 / 3]}
    my='38px'
    mx='32px'
    maxWidth='262px'>
    <GatsbyImage image={image} alt={title} />
    <Text
      color='nextonBlue'
      fontSize={['26px', '30px']}
      lineHeight='1.3'
      letterSpacing='-1px'
      textAlign='center'
      mt='10px'>
      {title}
    </Text>
    <Text
      color='nextonDarkGrey'
      fontSize={['22px', '24px']}
      letterSpacing='-1.5px'
      lineHeight='1.3'
      textAlign='center'
      fontWeight='300'
      fontStyle='italic'
      mt='10px'>
      {subtitle}
    </Text>
  </Flex>
);

const OurValues = ({ _academyImages }) => {
  const { values } = useStaticQuery(graphql`
    {
      values: allFile(filter: { relativeDirectory: { eq: "images/careers/values" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED, formats: [PNG])
          }
          name
        }
      }
    }
  `);
  const valuesImages = {};
  values.nodes.forEach(img => {
    valuesImages[camelCase(img.name)] = { ...img.childImageSharp.gatsbyImageData };
  });

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      py='75px'
      // pb="40px"
      px={['0px', '20px']}>
      <Text color='nextonBlue' fontSize={['40px', '50px']} letterSpacing='-1.5px' mb='45px' as='h2' fontWeight='400'>
        Our Values
      </Text>
      <Flex flexWrap='wrap' justifyContent='center'>
        <ValueItem
          image={valuesImages.simplicity}
          title='Simplicity is better than complexity'
          subtitle='Time Optimization'
        />
        <ValueItem image={valuesImages.together} title='Together we create magic' subtitle='Time Optimization' />
        <ValueItem image={valuesImages.challenge} title='We challenge  the status quo' subtitle='Value Creation' />
        <ValueItem
          image={valuesImages.wisdom}
          title='With humility comes wisdom'
          subtitle='Improvement Opportunities'
        />
        <ValueItem image={valuesImages.weSay} title='We do as we say' subtitle='Transparency' />
        <ValueItem image={valuesImages.changeLives} title='We change lives' subtitle='Talent & Experience' />
        <ValueItem image={valuesImages.global} title='Global yet local' subtitle='Remote' />
      </Flex>
    </Flex>
  );
};

export default OurValues;
